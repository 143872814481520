'use strict';

$(document).ready(function () {

    var getVideoTag = function(data) {        
        return [
            '<video '+data.mp4Attr.join(' ')+' poster="'+data.image+'">',
            '    <source src="'+data.videoURL+'" type="video/mp4">',
            '    Your browser does not support the video tag.',
            '</video>'
        ].join('');
    };

    var getEmbedIframe = function(data) {
        return [
            '<div class="video-component row">',
            '    <div class="embed-responsive embed-responsive-'+data.aspectRatio+'">',
            '        <iframe class="embed-responsive-item" src="'+data.videoURL+'"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            '    </div>',
            '</div>'
        ].join('');
    };

    $('.video-component--modal').on('shown.bs.modal', function (e) {
        var $videoComponent = $(e.relatedTarget);
        var data = !$videoComponent.hasClass('content-asset') ? $videoComponent.data('video') : eval($videoComponent.data('video'));
        var $modalBody = $(this).find('.modal-body');        

        $modalBody.html(data.isMP4 ? getVideoTag(data) : getEmbedIframe(data));

    }).on('hidden.bs.modal', function(e){
        var $iframe = $(this).find('iframe');
        var $video = $(this).find('video');

        if (!!$iframe.length) {
            $iframe.attr('src', $iframe.attr('src'));
        } else if (!!$video.length) {
            $video.get(0).pause();
            $video.get(0).currentTime = 0;
        }
        $(this).find('.modal-body').html('');

    });
});
